
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Privacy extends Vue {

    created(): void {
        this.$meta.use({
            title: 'Privacy Policy | StatHub.gg',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg - Privacy Policy.'
            }
        })
    }

}
